<template>
  <div class="mobile-enter box-shadow">
    <div v-show="state" class="mobile-enter-open">
      <div class="flex flex-center mobile-enter-open-title p-a-10">
        <span class="flex1 text-center font-weight-bolder p-l-20">手机订货</span>
        <i @click="changeState" class="el-icon-close font-size-20 cursor-pointer"></i>
      </div>
      <el-divider></el-divider>
      <div class="box-sizing p-a-20">
        <div class="box-shadow p-a-10 offiaccount-code-box">
          <img
            class="w-140 h-140"
            :src="offiaccount || siruiOffiaccountIcon"
            alt="公众号"
            style="display:inline-block;"
          />
        </div>
        <div class="font-size-12 text-center m-t-10">扫一扫关注公众号</div>
        <div class="box-shadow p-l-10 p-r-10 p-t-20 p-b-20 mobile-qrcode-box flex flex-center m-t-20">
          <div ref="mobileQRCode" class="w-120 h-120" id="mobileQRCode"></div>
        </div>
        <div class="font-size-12 text-center m-t-10">扫一扫进入移动端</div>
        <div class="box-shadow p-a-10 small-program-code-box m-t-20">
          <img class="w-140 h-140" :src="miniprogram || smallProgramCodeIcon" alt="" />
        </div>
        <div class="font-size-12 text-center m-t-10">扫一扫进入小程序</div>
      </div>
    </div>
    <div v-show="!state" class="mobile-enter-close" @click="changeState">
      <img class="w-30" :src="phoneIcon" alt="" />
      <span class="m-t-10">手机端进入</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import QRCode from "qrcodejs2";

export default {
  name: "MobileEnter",
  data() {
    return {
      desc: "手机端进入",
      siruiOffiaccountIcon: require("@/assets/img/sirui_offiaccount.png"), // 思锐公众号
      phoneIcon: require("@/assets/img/topNav/phone.png"),
      smallProgramCodeIcon: require("@/assets/img/small-program-code.png"),
      w: 120,
      state: true,
      qrcode: null
    };
  },
  computed: {
    ...mapState("companyInfo", {
      emblem: state => {
        return state.emblem;
      },
      offiaccount: state => {
        return state.company.qrCode.offiaccount;
      },
      miniprogram: state => {
        return state.company.qrCode.miniprogram;
      }
    })
  },
  watch: {
    emblem: {
      immediate: true,
      handler: function() {
        if (this.emblem) {
          setTimeout(() => {
            this.setLogoImg();
          }, 100);
        }
      }
    }
  },
  mounted() {
    this.initMobileQRCode();
  },
  methods: {
    initMobileQRCode() {
      this.qrcode = new QRCode(this.$refs.mobileQRCode, {
        width: this.w,
        height: this.w,
        text: `https://${window.location.hostname}/h5`
      });
    },
    setLogoImg() {
      const w = this.w;
      const logoImg = new Image();
      logoImg.setAttribute("crossOrigin", "Anonymous");
      logoImg.src = this.emblem;
      logoImg.onload = () => {
        const qrImg = this.qrcode._el.getElementsByTagName("img")[0];
        const canvas = this.qrcode._el.getElementsByTagName("canvas")[0];
        const ctx = canvas.getContext("2d");
        canvas.style.display = "inline-block";
        // 设置logo的大小为二维码图片缩小的4倍
        ctx.drawImage(logoImg, (w - w / 4) / 2, (w - w / 4) / 2, w / 4, w / 4);
        qrImg.src = canvas.toDataURL();
        qrImg.style.display = "none";
      };
    },
    changeState() {
      this.state = !this.state;
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile-enter {
  top: 136px;
  right: 20px;
  z-index: 100;
  position: fixed;
  border-radius: 10px;
  background-color: #ffffff;

  .mobile-enter-close {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }

  .mobile-enter-open {
    width: 200px;
    box-sizing: border-box;

    .el-divider--horizontal {
      margin: 0;
    }

    .offiaccount-code-box,
    .mobile-qrcode-box,
    .small-program-code-box {
      border-radius: 10px;
    }
  }

  .mobile-enter-close:active,
  .el-icon-close:active {
    opacity: 0.5;
  }
}

.box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
