<template>
  <div class="store-com flex flex-center opacity-5 width-full">
    <div class="flex flex-center">
      <img class="logo" src="@/assets/logo.png" alt="logo" />
    </div>
    <div class="font-size-14 text-999 font-weight-bolder m-l-10">
      <div>东方思锐软件提供IT技术支持</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StoreCom",
  data() {
    return {
      desc: "思锐订货系统COM组件"
    };
  }
};
</script>

<style lang="scss" scoped>
.store-com {
  left: 0;
  bottom: 10px;
  position: fixed;
  .logo {
    width: 40px;
    height: 40px;
  }
}
</style>
