<template>
  <div class="copyright-com flex flex-center flex-column width-full text-info">
    <div>
      <span>客服热线：</span>
      <span>{{ companyInfo.servicePhone || phone }}</span>
      <span class="m-l-10">联系地址：</span>
      <span>{{ companyInfo.address || address }}</span>
    </div>
    <span class="m-t-10">
      ©北京东方思锐科技有限责任公司 京ICP备05054864号-3 | 增值电信业务经营许可证：京B2-20221222
    </span>
  </div>
</template>

<script>
export default {
  name: "CopyrightCom",
  data() {
    return {
      desc: "思锐订货系统版权组件",
      phone: "010-60219478",
      address: "北京市大兴区金星路12号院奥宇科技英巢2号楼1005室"
    };
  },
  computed: {
    companyInfo() {
      return this.$store.state.companyInfo.company;
    }
  }
};
</script>

<style lang="scss" scoped>
.copyright-com {
  margin-top: 60px;
  margin-bottom: 30px;
}
</style>
